
  
  
  import FETCHING from '../../library/fetching'
  import DATAMASTER from '../../library/dataMaster'
  
  export default {
    data() {
      return {
  
        form : {
          id : '',
          saldo : '',
          non_saldo : '',
          berharga : '',
          total_saldo : '',
          tahun : '',
          unit_kerja_id : '',
        },
  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
        filterku : {
            tahun : '',
            unit_kerja : '',
            instansi : '',
        },

        tipeku: null,

  
        list_data : [],
  
        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        cek_load_data : true,
  
  
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        btn_add: false,
  
  
        FETCHING : FETCHING,
        DATAMASTER : DATAMASTER,
      }
    },
    methods: {
  
  
      getView : function(){
      this.$store.commit("shoWLoading");
      
      fetch(this.$store.state.url.URL_saldo + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                menu_klp : this.form.menu_klp,
                // id_bku : this.filterku.id_bku,
                 unit_kerja_id : this.form.unit_kerja_id,

                instansi : this.filterku.instansi,
                unit_kerja : this.filterku.unit_kerja,
                tahun : this.filterku.tahun,

                tipeku : this.tipeku,
            })
        })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },
  
  
      addData : function() {
        console.log(this.form);
        fetch(this.$store.state.url.URL_saldo + "addData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      },
  
  
      editData : function(){
        fetch(this.$store.state.url.URL_saldo + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(E){
        fetch(this.$store.state.url.URL_saldo + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },
  
      selectData : function(data){

        console.log(data);
          this.form.id = data.id;
          this.form.saldo = data.saldo;
          this.form.non_saldo = data.non_saldo;
          this.form.total_saldo = data.total_saldo;
          this.form.berharga = data.berharga;
          this.form.unit_kerja_id = data.unit_kerja_id;
          this.form.tahun = data.tahun
      },
  
  
      // ====================================== CONTOH eDOC ====================================
      async onChangexInstansi(){
        await DATAMASTER.getInstansiAsync("");
        this.onChangexUnitKerja();
      },
      async onChangexUnitKerja(){
        // console.log("yyyyyyyyyyyy");
        var unitkerjax = await DATAMASTER.getUnitKerjaAsync(this.filterku.instansi)
        // console.log("xxxxxxxxxxxxx");
        console.log(unitkerjax[0].id);
        this.filterku.unit_kerja = unitkerjax[0].id
        // console.log("xxxxxxxxxxxxx");
        console.log(this.filterku.unit_kerja);
        this.getView();
      },


      // async asyncFetchUrusan(){
      //   await DATAMASTER.postMasterUrusan('');
      //   this.form.urusan_kode = this.$store.state.list_urusan[0].kode;
      //   this.asyncFetchUrusanBidang(this.form.urusan_kode)
      // },
      // async asyncFetchUrusanBidang(val){
      //   await DATAMASTER.postMasterUrusanBidang(val)
      // },
      // ====================================== CONTOH eDOC ====================================
  
    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_program_filter : function (val, update) {
          update(() => {
            if (val === '') {}
            else {DATAMASTER.postMasterProgramAutoComplete(val, this.form.unit_kerja_id)}
          })
      },
  
      // ====================================== CONTOH AUTOCOMPLETE ====================================
  
  
  
  
  
  
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },

            indexing : function(index){
                var idx = ((this.page_first-1)*this.page_limit)+index
                return idx
            },
  
  
      // ====================================== PAGINATE ====================================
  
  
  
  
  
  
  
    },
  
    mounted () {

      let profilex = JSON.parse(localStorage.profile);
      let profile = profilex.profile;
      this.form.unit_kerja_id = profile.unit_kerja;
      this.filterku.instansi = profile.instansi_id;

      this.tipeku = profile.trista_monev;
      // this.filter.tipeku = profilex.trista_monev;
      console.log("apa sgala" + profile.trista_monev);
      // this.filterku.unit_kerja = profile.unit_kerja;

      const d = new Date();
      let year = d.getFullYear();
      this.form.tahun = year;
      this.filterku.tahun = year;

      console.log(profile)


      DATAMASTER.getTahun();


      DATAMASTER.postMasterProgramAutoComplete("", this.form.unit_kerja_id)
      
      // this.asyncFetchUrusan();
    //   DATAMASTER.postMasterUrusan('')
      this.onChangexInstansi();
      // this.getView();

    },
  }
  